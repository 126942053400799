<template>
    <div>
        
        <b-row>
            <b-col md="12" class="mt-5 ml-5">
                <b-img :src="require('@/assets/images/pages/logo-1.png')" class="congratulations-img-center"
                    fluid />
               
            </b-col>
        </b-row>
        <div class="p-2">
            <b-row class="mt-1 match-height">
                <b-col md="6" xs='6' sm="6" lg="6" cols="6">
                    <b-card class="d-flex align-items-center text-center text-primary">
                        <b-row>
                            <b-col md="12">
                                <feather-icon class="" icon="UnlockIcon" size="40"></feather-icon>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <strong style="font-size: 20px;"> Login</strong>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col md="6" xs='6' sm="6" lg="6" cols="6">
                    <router-link to="/gis">
                        <b-card class="d-flex align-items-center text-center text-primary">
                        <!-- <b-img :src="require('@/assets/images/pages/pelopor_keselamatan.png')" class="congratulations-img-left"
                    fluid /> -->
                        <b-row>
                            <b-col md="12">
                                <feather-icon class="" icon="MapIcon" size="40"></feather-icon>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <strong style="font-size: 15px;">Titik Rawan Kecelakaan</strong>
                            </b-col>
                        </b-row>
                    </b-card>
                    </router-link>
                </b-col>
                <b-col md="6" xs='6' sm="6" lg="6" cols="12">
                    <b-card class="d-flex align-items-center text-center text-primary">
                        <b-row>
                            <b-col md="12">
                                <feather-icon class="" icon="TwitchIcon" size="40"></feather-icon>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="12">
                                <strong style="font-size: 15px;">Pengaduan Masyarakat</strong>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        BCard,
        BAvatar
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'
    import {
        togglePasswordVisibility
    } from '@core/mixins/ui/forms'
    import store from '@/store/index' 
    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
            BCard,
            BAvatar
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                status: '',
                password: '',
                userEmail: '',
                sideImg: require('@/assets/images/pages/coming-soon.svg'),
                logo_siembal: require('@/assets/images/pages/logo-1.png'),
                fllaj: require('@/assets/images/pages/fllaj.png'),
                // validation rulesimport store from '@/store/index'
                required,
                email,
            }
        },
        computed: {
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/logo-1.png')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            async Profil(isAuthenticated) { 

            },
            async get_ip() { 

            },

            validationForm() { 
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>